import React from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import LoginForm from "./containers/Login";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageNotFound from "./containers/PageNotFound";
import ProfileContainer from "./containers/ProfileContainer";
import Devices from "./containers/Devices";
import Resellers from "./containers/Resellers";
import AssignDevices from "./containers/AssignDevices";
import ViewDevices from "./containers/ViewDevices";
import ResellerUsers from "./containers/ResellerUsers";
import { getTheme } from "../src/partners/utils";
import DeviceInfo from "./containers/DeviceInfo";
import ResellerPortGroups from "./containers/ResellerPortGroups";
import Report from "./containers/Report";
import ManageSims from "./containers/sims/ActivateSims";
import AllSims from "./containers/AllSims";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import SimRackList from "./containers/SimRackList";
import DeviceInfoSignal from "./containers/DeviceInfoSignal";

//  Crisp.configure("b0cb0e74-1ebb-41b4-86cb-37c912f5c945", {
//    autoload: false,
//  });

function App() {
  return (
    <ThemeProvider theme={getTheme()}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true} component={LoginForm} />
          <Route path="/profile" exact={true} component={ProfileContainer} />
          <Route path="/devices" exact={true} component={Devices} />
          <Route path="/resellers" exact={true} component={Resellers} />
          <Route
            path="/device/:device_uuid/info"
            exact={true}
            component={DeviceInfoSignal}
          />
          <Route
            path="/device/:device_uuid/info/signal"
            exact={true}
            component={DeviceInfoSignal}
          />
          <Route
            path="/reseller/:reseller_uuid/assign-unassign-devices"
            exact={true}
            component={AssignDevices}
          />
          <Route
            path="/reseller/:reseller_uuid/devices"
            exact={true}
            component={ViewDevices}
          />
          <Route
            path="/reseller/:reseller_uuid/users"
            exact={true}
            component={ResellerUsers}
          />
          <Route
            path="/reseller/:reseller_uuid/assign-port-groups"
            exact={true}
            component={ResellerPortGroups}
          />
          <Route path="/report" exact={true} component={Report} />
          <Route path="/manage-sims" exact={true} component={ManageSims} />
          <Route path="/all-sims" exact={true} component={AllSims} />
          <Route
            path="/forgot-password"
            exact={true}
            component={ForgotPassword}
          />
          <Route
            path="/reset-password/:reset_token"
            exact={true}
            component={ResetPassword}
          />
          <Route path="/sim-racks" exact={true} component={SimRackList} />

          <Route path="*" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
