import React from "react";

import SignalStrength from "./SignalStrength";
import { parseSignal } from "../utils/signalParser";

const AntennaSignalStrength = ({ deviceType, cellInfo }) => {
  const totalAntenna = 4;
  const signals = cellInfo?.sci_ca_info;

  if (!signals) return null;

  const mapped_signals = parseSignal(signals).reduce((acc, signal) => {
    // Only process RSRP signals
    if (signal.signal_type !== "RSRP") return acc;

    let processedSignals = signal?.signals.slice(0, totalAntenna);

    // If totalAntenna is 4, check if the last two signal values are less than -10000
    if (totalAntenna === 4) {
      const lastTwoSignals = processedSignals.slice(-2);
      if (
        lastTwoSignals.length === 2 &&
        lastTwoSignals.every((val) => val < -10000)
      ) {
        // If both last two signals are less than -10000, exclude them and keep only the first two
        processedSignals = processedSignals.slice(0, 2);
      }
    }

    return {
      signals: processedSignals,
      band: signal.band_type,
    };
  }, {});

  return (
    <>
      {mapped_signals?.signals?.map((value) => (
        <span style={{ marginRight: "10px" }} key={value}>
          <SignalStrength key={value} signal={value} cellInfo={cellInfo} />
        </span>
      ))}
    </>
  );
};

export default AntennaSignalStrength;
