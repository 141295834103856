import React, { useRef, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, ScaleControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const FitBounds = ({ coordinates }) => {
  const map = useMap();
  useEffect(() => {
    if (coordinates?.length > 0) {
      const bounds = L.latLngBounds(
        coordinates.map((coord) => [coord.latitude, coord.longitude])
      );
      const zoom = Math.max(7, Math.min(map.getBoundsZoom(bounds), 17));
      map.fitBounds(bounds, { maxZoom: zoom });
    }
  }, [coordinates, map]);
  return null;
};

const Map = ({
  coordinates,
  defaultCenter,
  zoomLevel,
  bounds,
  height = "300px",
}) => {
  const mapRef = useRef(null);
  const [mapFitted, setMapFitted] = useState(false);
  let calculatedBounds;
  let calculatedCenter;

  if (coordinates && coordinates.length > 0) {
    if (coordinates.length === 1) {
      // If only one coordinate, set center and bounds to that coordinate
      const coord = coordinates[0];
      calculatedCenter = [coord.latitude, coord.longitude];
      calculatedBounds = L.latLngBounds(
        [coord.latitude, coord.longitude],
        [coord.latitude, coord.longitude]
      );
    } else {
      // Calculate bounds and center for multiple coordinates
      if (!bounds) {
        const latLngs = coordinates.map((coord) => [
          coord.latitude,
          coord.longitude,
        ]);
        calculatedBounds = L.latLngBounds(latLngs);
      }

      if (!defaultCenter) {
        let sumLat = 0;
        let sumLng = 0;
        coordinates.forEach((coord) => {
          sumLat += coord.latitude;
          sumLng += coord.longitude;
        });
        const avgLat = sumLat / coordinates.length;
        const avgLng = sumLng / coordinates.length;
        calculatedCenter = [avgLat, avgLng];
      }
    }
  }

  useEffect(() => {
    if (mapRef.current && calculatedBounds && !mapFitted) {
      const map = mapRef.current;
      const padding = parseInt(height) * 0.15; // Adjust padding based on height
      map.fitBounds(calculatedBounds, { padding: [padding, padding] });
      setMapFitted(true);
    }
  }, [calculatedBounds, height, mapFitted]);

  if (!coordinates || coordinates?.length === 0) {
    return <p>GPS and tower location not available.</p>;
  }

  return (
    <MapContainer
      style={{ height: height, width: '80%' }}
      center={defaultCenter ? defaultCenter : calculatedCenter}
      zoom={zoomLevel || 13}
      bounds={bounds ? bounds : calculatedBounds}
      ref={mapRef}
    >
      <TileLayer
        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <FitBounds coordinates={coordinates} />
      <ScaleControl position="bottomleft" />
      {coordinates.map((coord, index) => (
        <Marker
          key={index}
          position={[coord.latitude, coord.longitude]}
          icon={
            new L.Icon({
              iconUrl: coord?.type === "device" ? "/device.svg" : "/tower.svg",
              iconRetinaUrl:
                coord?.type === "device" ? "/device.svg" : "/tower.svg",
              iconSize: [25, 25],
              iconAnchor: [12, 25],
              popupAnchor: [1, -34],
              shadowSize: [25, 25],
            })
          }
        >
          <Popup>
            <div>
              <p>
                <strong>Latitude:</strong> {coord.latitude}
              </p>
              <p>
                <strong>Longitude:</strong> {coord.longitude}
              </p>
              {coord.popupContent && <p>{coord.popupContent}</p>}
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
