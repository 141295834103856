import React, { useState } from "react";

import { Cancel as CancelIcon, Search as SearchIcon } from "@material-ui/icons";
import { IconButton, InputBase, Paper } from "@material-ui/core";

export default function Search({
  searchValue,
  changeSearchValue,
  clearSearch,
}) {
  const [fieldValue, setFieldValue] = useState(searchValue || "");

  const handleChange = (e) => {
    setFieldValue(e.target.value);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      changeSearchValue(fieldValue);
    }
  };

  return (
    <Paper
      component="form"
      style={{
        padding: "2px 6px",
        display: "flex",
        alignItems: "center",
        width: "25%",
      }}
    >
      <InputBase
        style={{ marginLeft: 5, flex: 1 }}
        onChange={handleChange}
        onSubmit={(e) => e.preventDefault()}
        onKeyDown={handleEnter}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={fieldValue}
      />
      <IconButton
        disabled={!fieldValue}
        onClick={() => changeSearchValue(fieldValue)}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        disabled={!fieldValue}
        onClick={() => {
          setFieldValue("");
          clearSearch();
        }}
        aria-label="search"
      >
        <CancelIcon />
      </IconButton>
    </Paper>
  );
}
