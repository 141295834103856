import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Tooltip,
  Box,
  Button,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { simActivityStatusMap } from "../utils/constants";
import DataTable from "./DataTable";

const SimActivityLog = ({
  device_serial,
  device_uuid,
  setLoading,
  setMessage,
  loading,
}) => {
  const [simActivity, setSimActivity] = useState([]);

  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 20,
    total: 1,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setPageSize(20);
    setCurrentPage(1);
  };

  const columns = [
    {
      field: "startdate",
      headerName: "Start Date",
      filterable: false,
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        params.row.startdate &&
        `${moment
          .utc(new Date(params.row.startdate * 1000))
          .local()
          .format("lll")}`,
    },
    {
      field: "stopdate",
      headerName: "Stop Date",
      filterable: false,
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        params.row.stopdate &&
        `${moment
          .utc(new Date(params.row.stopdate * 1000))
          .local()
          .format("lll")}`,
    },
    {
      field: "iccid",
      headerName: "Sim",
      filterable: false,
      sortable: false,
      width: 200,
    },
    {
      field: "operatorname",
      headerName: "Carrier",
      filterable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "stopcause",
      headerName: "Stop Cause",
      filterable: false,
      sortable: false,
      flex: 2,
      valueGetter: (params) => simActivityStatusMap[params.row.stopcause],
    },
  ];

  useEffect(() => {
    if (device_uuid !== null && open) {
      setLoading(true);
      axios
        .get(
          `/device/${device_uuid}/sim_activity?page=${currentPage}&page_size=${pageSize}`
        )
        .then(({ data }) => {
          setSimActivity(data.rlogs);
          setMetaData({
            page: data.page,
            page_size: data.page_size,
            total: data.total,
          });
          setLoading(false);
        })
        .catch((error) => {
          setMessage({ type: "error", text: error.response.data.detail });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [open, pageSize, currentPage]);

  return (
    <>
      <Tooltip title="See Sim Activity Log">
        <Button style={{ cursor: "pointer", marginLeft: '5px', padding: 4}} onClick={handleClickOpen} variant="contained">
          See logs
        </Button>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="simple-dialog-title">
          {device_serial} Sim Activity
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataTable
              columns={columns}
              data={simActivity}
              loading={loading}
              metaData={metaData}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SimActivityLog;
