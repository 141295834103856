import React, { useEffect, useState, useRef } from "react";
import Layout from "../layouts/Layout";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { DataGrid } from "@material-ui/data-grid";
import DateAndTime from "../components/DateAndTime";
import moment from "moment";
import { GREEN, RED } from "../utils/constants";
import PropTypes from "prop-types";
import { Alert } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";

const SimRackList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [rackData, setRackData] = useState([]);

  const getSimRacksData = () => {
    setLoading(true);
    axios
      .post("/sim-racks")
      .then(({ data }) => {
        const rackData = [];
        for (let rack_record of data.sim_racks) {
          rackData.push({ ...rack_record, pdus: data.pdus });
        }
        setRackData(rackData);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const powerOperationPDU = (uuid, operation) => {
    setLoading(true);
    axios
      .post(`/sim-rack/${uuid}/power-cycle`, { operation })
      .then(({ data }) => {
        setMessage("PDU operation initiated successfully");
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = useRef([
    {
      field: "name",
      headerName: "Name & Status",
      minWidth: 520,
      renderCell: (params) => (
        <>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>{params.row.name}</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={5}
              >
                <Grid item>
                  <Chip
                    label={params.row.status === 1 ? "Working" : "Unavailable"}
                    size="small"
                    style={{
                      fontWeight: 500,
                      background: params.row.status === 1 ? GREEN : RED,
                    }}
                  />
                </Grid>
                <Grid item>
                  <EditSimRack
                    simRackInfo={params.row}
                    setLoading={setLoading}
                    callOnSuccess={getSimRacksData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ),
    },

    {
      field: "ip",
      headerName: "IP:PORT",
      minWidth: 260,
      valueGetter: (params) => `${params.row.ip}:${params.row.tcp_port}`,
    },
    {
      field: "version_and_package",
      headerName: "Version/Package",
      minWidth: 230,
      valueGetter: (params) => `${params.row.version} / ${params.row.package}`,
    },
    {
      field: "last_service_check_status",
      headerName: "Service Status",
      minWidth: 175,
      renderCell: (params) => (
        <Chip
          label={params.row.last_service_check_status}
          size="small"
          style={{
            fontWeight: 500,
            background:
              params.row.last_service_check_status === "RUNNING" ? GREEN : RED,
          }}
        />
      ),
    },
    {
      field: "last_updated_at",
      headerName: "Last Updatd At",
      filterable: false,
      minWidth: 160,
      valueGetter: (params) =>
        params.row.last_updated_at &&
        parseInt(moment(params.row.last_updated_at).format("x")),
      renderCell: (params) =>
        params.row.last_updated_at && (
          <DateAndTime dateAndTime={params.row.last_updated_at} />
        ),
    },
    {
      field: "last_service_checked_at",
      headerName: "Last Service Checked At",
      filterable: false,
      minWidth: 160,
      valueGetter: (params) =>
        params.row.last_service_checked_at &&
        parseInt(moment(params.row.last_service_checked_at).format("x")),
      renderCell: (params) =>
        params.row.last_service_checked_at && (
          <DateAndTime dateAndTime={params.row.last_service_checked_at} />
        ),
    },
    {
      field: "last_service_restarted_at",
      headerName: "Last Service Restarted At",
      filterable: false,
      minWidth: 160,
      valueGetter: (params) =>
        params.row.last_service_restarted_at &&
        parseInt(moment(params.row.last_service_restarted_at).format("x")),
      renderCell: (params) =>
        params.row.last_service_restarted_at && (
          <DateAndTime dateAndTime={params.row.last_service_restarted_at} />
        ),
    },
    {
      field: "pdu_id",
      headerName: "PDU ID",
      minWidth: 140,
    },
    {
      field: "pdu_load_id",
      headerName: "PDU Load ID",
      minWidth: 170,
    },
    {
      field: "ip",
      headerName: "IP:PORT",
      minWidth: 260,
      valueGetter: (params) => `${params.row.ip}:${params.row.tcp_port}`,
    },
    {
      field: "skip_status_check",
      headerName: "Skip Status Check ?",
      minWidth: 260,
      valueGetter: (params) => (params.row.skip_status_check ? "Yes" : "No"),
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 400,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          {params.row.pdu_id && params.row.pdu_load_id && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginRight: "5px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  powerOperationPDU(params.row.uuid, "CYCLE");
                }}
              >
                Power Cycle
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ marginRight: "5px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  powerOperationPDU(params.row.uuid, "ON");
                }}
              >
                On
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  powerOperationPDU(params.row.uuid, "OFF");
                }}
              >
                Off
              </Button>
            </>
          )}
        </>
      ),
    },
  ]);

  useEffect(() => {
    getSimRacksData();
  }, []);

  return (
    <Layout
      loading={loading}
      error={error}
      successMessage={message}
      pageTitle="Manage Sim Racks"
      containerMaxWidth="xl"
    >
      <Box pt={2}>
        <DataGrid
          rows={rackData}
          columns={columns.current}
          disableSelectionOnClick
          autoHeight
          paginationMode="server"
          loading={loading}
          getRowId={(row) => row.uuid}
          density="compact"
        />
      </Box>
    </Layout>
  );
};

const EditSimRack = (props) => {
  const { getPDUs, simRackInfo, setLoading, callOnSuccess } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [pdus, setPdus] = useState([]);
  const [pduId, setPduId] = useState(simRackInfo?.pdu_id);
  const [pduLoadId, setPduLoadId] = useState(simRackInfo?.pdu_load_id);
  const [skipStatusCheck, setSkipStatusCheck] = useState(
    simRackInfo?.skip_status_check
  );

  const handleClose = () => {
    setOpen(false);
  };

  const updateSimRackData = () => {
    const updateData = {
      pdu_id: pduId,
      pdu_load_id: pduLoadId,
      skip_status_check: skipStatusCheck,
    };

    setLoading(true);
    axios
      .put(`/sim-rack/${simRackInfo.uuid}`, updateData)
      .then((response) => {
        if (response.status === 200) {
          setMessage({
            type: "success",
            text: "Sim Rack Data Updated Successfully.",
          });
          callOnSuccess();
        }
      })
      .catch((error) => {
        setMessage({
          type: "error",
          text: error.response.data.detail,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Tooltip title="Update Details">
        <IconButton
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Update Details for {simRackInfo?.name}
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}
          <Box pb={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl style={{ minWidth: 180 }}>
                  <InputLabel id="select-plan">Select PDU</InputLabel>
                  <Select
                    labelId="select-plan"
                    id="plan"
                    value={pduId}
                    onChange={(e) => setPduId(e.target.value)}
                  >
                    {simRackInfo?.pdus.map((pdu, index) => (
                      <MenuItem value={pdu.id} key={index}>
                        {pdu.host}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="full_name"
                  label="PDU Load Id"
                  onChange={(e) => setPduLoadId(e.target.value)}
                  placeholder="Enter PDU Load Id"
                  value={pduLoadId}
                />
              </Grid>

              <Grid item>
                <Tooltip title="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={skipStatusCheck}
                        onChange={(e) => {
                          setSkipStatusCheck(e.target.checked);
                        }}
                        size="small"
                      />
                    }
                    label={
                      <Typography variant="body2">Skip Status Check</Typography>
                    }
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={updateSimRackData}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

EditSimRack.propTypes = {
  simRackInfo: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  callOnSuccess: PropTypes.func.isRequired,
};

export default SimRackList;
