export const GREEN = "#4caf50a3";
export const RED = "#ff000073";
export const YELLOW = "#ffc107bd";
export const BLUE = "#2196f3a6";
export const GREY = "#9e9e9e";

export const SPEED_TEST_TYPES = {
    IPERF : 6,
    NORMAL : 5
}

export const NDIAG_STEPS = {
    0: "None",
    1 : "Diag Start",
    2 : "Net Scan Start",
    3: "Net Scan End",
    10 : "Sim Allocate",
    11: "iPERF Start",
    12 : "iPERF End",
    30: "Diag End"
}


export const simActivityStatusMap = {
    "1": "Reset at End of Lease Time",
    "2": "Reset at End of Lease Time, Breaking Call",
    "3": "Reset at End of Call Lease Time",
    "4": "Reset at End of Call Lease Time, Breaking Call",
    "5": "Max consecutive non connected calls reached",
    "6": "All Credit has been used",
    "7": "Manual Reset",
    "8": "Invalid Pin Code",
    "9": "Sim Puked",
    "10": "Unable to get Sim assigned",
    "11": "Sim on Gsm Port is not the assigned one !",
    "12": "Top Up Action needed",
    "13": "Gsm Module restarted?",
    "14": "Sim did not get Registered to Network in given time",
    "15": "Activation Script Failed",
    "16": "Refilling Script Failed",
    "17": "All Credit has been used",
    "18": "Daily Number of Calls reached",
    "19": "Reset at Rule Max Numbers of Calls",
    "20": "Reset at Rule Max Numbers of Calls, Breaking Call",
    "21": "SimServer Credit greater than Credit Check one",
    "22": "Port Temporarily Disabled due to Low ASR",
    "23": "Port Temporarily Disabled due to Low ACD",
    "24": "Port Disabled due to Consecutive Low ASR or ACD",
    "25": "Reset at Rule Max Numbers of SMS (not yet implemented)",
    "26": "Force Reset at Rule Max Numbers of SMS (not yet implemented)",
    "27": "Daily Number of SMS reached",
    "28": "Deactivated from Transaction Parsing",
    "29": "Sim Deactivated due to Successive Low Duration Calls",
    "30": "Sim Array is Unreachable",
    "31": "Not enough Licenced Ports",
    "32": "Not enough Licenced SimBoards",
    "33": "R2D2/Ascom Reset",
    "34": "Max consecutive undeliverable SMS",
    "35": "Release after Device Disconnected Timeout",
    "36": "Daily Data Credit reached",
    "37": "Allocation Rule Max Data reached",
    "38": "Daily Number of SMS Error reached (Sim Disabled)",
    "39": "Max consecutive SMS errors",
    "40": "Timeout - Communication Error - ...",
    "41": "Data Registration Failed",
    "42": "Network Registration Failed",
    "43": "Forced reset",
    "44": "Max Daily Activation Reached",
    "45": "Max Daily Refilling Reached",
    "46": "DATA Connection unstable",
    "47": "Unknown cause (47)",
    "48": "Unknown cause (48)",
    "49": "Unknown cause (49)",
    "50": "Unknown cause (50)",
    "51": "Unknown cause (51)",
    "52": "Unknown cause (52)",
    "53": "Unknown cause (53)",
    "54": "Unknown cause (54)",
    "55": "Unknown cause (55)",
    "56": "Unknown cause (56)",
    "57": "Unknown cause (57)",
    "58": "Unknown cause (58)",
    "59": "Unknown cause (59)",
    "60": "Mifi Country has Changed",
    "61": "No More Credit on Mifi Device (SimPort)",
    "62": "No More Credit on Pass for Mifi Device (SimPort)",
    "63": "End of Pass for Mifi Device",
    "64": "Perform Factory Reset on Mifi Device",
    "65": "Reboot Mifi Device",
    "66": "Force new ISM connection from Mifi Device",
    "67": "Unknown cause (66)",
    "68": "Unknown cause (67)",
    "69": "Unknown cause (68)",
    "70": "RoamWifi asked for Reset on Hearbeat",
    "71": "External Sim Manager Reset",
    "72": "Sim Released to execute Scan Network",
    "73": "Sim Released to execute Analyze Network",
    "74": "Sim Released after SpeedTest during Analyze Network",
    "99": "Unknown cause (99)",
    "0": "SIM card is currently allocated to a GSM port for use",
};
